import './styles.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import BuyButton from '../BuyButton';
import SellButton from '../SellButton';
import RedDownArrowIcon from '../icons/RedDownArrowIcon';
// import BitcoinBtcIcon from '../icons/BitcoinBtcIcon';
// import BitcoinEthIcon from '../icons/BitcoinEthIcon';
// import BitcoinUsdIcon from '../icons/BitcoinUsdIcon';
// import CosmosCoinIcon from '../icons/CosmosCoinIcon';
// import AvalancheCoinIcon from '../icons/AvalancheCoinIcon';
// import EthereumCoinIcon from '../icons/EthereumCoinIcon';
import GreenUpArrowIcon from '../icons/GreenUpArrowIcon';
import NewZealandUsdIcon from '../icons/NewZealandUsdIcon';
import EuroPoundSterlingIcon from '../icons/EuroPoundSterlingIcon';
import EuroJapaneseYenIcon from '../icons/EuroJapaneseYenIcon';
import EuroNewZealandDollarIcon from '../icons/EuroNewZealandDollarIcon';
import CanadianDollarJapaneseYenIcon from '../icons/CanadianDollarJapaneseYenIcon';
import UsdSwissFrancIcon from '../icons/UsdSwissFrancIcon';

const icons = {
  NZDUSD: <NewZealandUsdIcon />,
  EURGBP: <EuroPoundSterlingIcon />,
  EURJPY: <EuroJapaneseYenIcon />,
  EURNZD: <EuroNewZealandDollarIcon />,
  CADJPY: <CanadianDollarJapaneseYenIcon />,
  USDCHF: <UsdSwissFrancIcon />,
};

// const data = [
//   {
//     id: 1,
//     icon: <BitcoinBtcIcon />,
//     title: 'Bitcoin Gold/Bitcoin BTGBTC',
//     price: '$34567',
//     change: '34%',
//   },
//   {
//     id: 2,
//     icon: <BitcoinEthIcon />,
//     title: 'Bitcoin Gold/Ethereum BTGETH',
//     price: '$3456',
//     change: '34,2%',
//   },
//   {
//     id: 3,
//     icon: <BitcoinUsdIcon />,
//     title: 'Bitcoin Gold BTGUSD',
//     price: '$345',
//     change: '3,23%',
//   },
//   {
//     id: 4,
//     icon: <CosmosCoinIcon />,
//     title: 'Cosmos ATMUSD',
//     price: '$34',
//     change: '34,23%',
//   },
//   {
//     id: 5,
//     icon: <AvalancheCoinIcon />,
//     title: 'Avalanche AVAXUSD',
//     price: '$3',
//     change: '12%',
//   },
//   {
//     id: 6,
//     icon: <EthereumCoinIcon />,
//     title: 'EOS/Ethereum EOSETH',
//     price: '$34567',
//     change: '1%',
//   },
// ];

const CfdTableDesktop = () => {
  const { t } = useTranslation();
  const { currencies } = useSelector((state) => state.currencies);

  return (
    <>
      <div className='cfd-table-desk'>
        <table className='cfd-table-desk_table'>
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
              <th>{t('currency')}</th>
              <th>{t('price')}</th>
              <th>{t('change')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {currencies?.slice(0, 6).map((item, index) => {
              return (
                <tr key={item.s}>
                  <td>{index + 1}</td>
                  <td>
                    <div className='bitcoin_name'>
                      {icons[item.s]}
                      <span className='bitcoin_name_title'>{item.alias}</span>
                    </div>
                  </td>
                  <td>
                    <div className='bitcoin_price'>{+item.lp.toFixed(5)}</div>
                  </td>
                  <td>
                    <div className='bitcoin_change'>
                      <span
                        className={cn('bitcoin_change_text', {
                          bitcoin_change_text_up: item.cpd > 0,
                        })}
                      >
                        {item.cpd.toFixed(2)}%
                      </span>
                      {item.cpd > 0 ? <GreenUpArrowIcon /> : <RedDownArrowIcon />}
                    </div>
                  </td>
                  <td>
                    <a href='https://app.i88.io/' target='_blank' rel='noreferrer'>
                      <div className='bitcoin_button'>
                        <BuyButton />
                      </div>
                    </a>
                  </td>
                  <td>
                    <a href='https://app.i88.io/' target='_blank' rel='noreferrer'>
                      <div className='bitcoin_button'>
                        <SellButton />
                      </div>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className='cfd-table-mob'>
        {currencies?.slice(0, 6).map((item) => {
          return (
            <div className='cfd-table-mob_item' key={item.s}>
              <div className='cfd-table-mob_item_title'>
                {icons[item.s]}
                <span> {item.alias}</span>
              </div>
              <div className='cfd-table-mob_item_wrapper'>
                <div className='cfd-table-mob_item_wrapper_price'>{+item.lp.toFixed(5)}</div>
                <div className='cfd-table-mob_item_wrapper_change'>
                  <span
                    className={cn({
                      bitcoin_change_text_up: item.cpd > 0,
                    })}
                  >
                    {item.cpd.toFixed(2)}%
                  </span>
                  {item.cpd > 0 ? <GreenUpArrowIcon /> : <RedDownArrowIcon />}
                </div>
              </div>
            </div>
          );
        })}
        <div className='cfd-table-mob_button'>
          <a href='https://app.i88.io/' target='_blank' rel='noreferrer'>
            <BuyButton />
          </a>
          <a href='https://app.i88.io/' target='_blank' rel='noreferrer'>
            <SellButton />
          </a>
        </div>
      </div>
    </>
  );
};

export default CfdTableDesktop;
