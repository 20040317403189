const RedDownArrowIcon = () => {
  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.11797 11.1256C6.31467 11.33 6.64185 11.33 6.83855 11.1256L10.8409 6.96625C11.1465 6.6486 10.9214 6.11957 10.4806 6.11957H2.47595C2.03512 6.11957 1.81 6.6486 2.11566 6.96625L6.11797 11.1256Z'
        fill='#D85447'
      />
      <rect
        width='3.34783'
        height='6.69565'
        rx='0.5'
        transform='matrix(1 0 0 -1 4.80432 7.19565)'
        fill='#D85447'
      />
    </svg>
  );
};

export default RedDownArrowIcon;
