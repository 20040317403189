import './styles.scss';
import HeaderMenu from '../../components/common/HeaderMenu';
import HeadSlider from '../../components/HeadSlider';

const MenuSliderSection = () => {
  return (
    <div className='main_slider_wrapper'>
      <HeaderMenu />
      <HeadSlider />
      {/* <img src='/img/main_tablet.png' alt='' /> */}
    </div>
  );
};

export default MenuSliderSection;
