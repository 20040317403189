import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import DecorLine from '../common/DecorLine';
import FormInput from '../common/FormInput';
import FormSelect from '../common/FormSelect';
import FormButton from '../common/FormButton';
import BitcoinUsdIcon from '../common/icons/BitcoinUsdIcon';
import UsdIcon from '../common/icons/UsdIcon';

const BuyForm = () => {
  const { t } = useTranslation();
  const btcToUsdCourse = 27244.7;

  const options1 = [
    { label: 'BTC', value: 'btc' },
    { label: 'BTG', value: 'btg' },
  ];

  const options2 = [
    { label: 'USD', value: 'usd' },
    { label: 'UAH', value: 'uah' },
  ];

  const [btcValue, setBtcValue] = useState('1');
  const [usdValue, setUsdValue] = useState('');

  const handleChangeBtcValue = (e) => {
    if (/^\d+(\.?\d*)?$/.test(e.target.value) || e.target.value === '') {
      setBtcValue(e.target.value);
      setUsdValue((btcToUsdCourse * +e.target.value).toFixed(2));
    }
  };

  const handleChangeUsdValue = (e) => {
    if (/^\d+(\.?\d*)?$/.test(e.target.value) || e.target.value === '') {
      setUsdValue(e.target.value);
      setBtcValue((+e.target.value / btcToUsdCourse).toFixed(4));
    }
  };

  useEffect(() => {
    setUsdValue((btcToUsdCourse * +btcValue).toFixed(2));
  }, []);

  return (
    <>
      <div className='buyform_title'>{t('buy_trading_instruments')}</div>
      <div className='buyform_wrapper'>
        <div className='buyform_wrapper_form'>
          <div className='buyform_wrapper_form_get'>
            <FormInput title='Get' value={btcValue} onChange={handleChangeBtcValue} />
            <FormSelect icon={<BitcoinUsdIcon />} options={options1} />
          </div>
          <div className='buyform_wrapper_form_pay'>
            <FormInput title='Pay' value={usdValue} onChange={handleChangeUsdValue} />
            <FormSelect icon={<UsdIcon />} options={options2} />
          </div>
          <div className='buyform_wrapper_form_decor'>
            <DecorLine />
          </div>
          <FormButton />
        </div>
        <div className='buyform_wrapper_text'>
          <span className='buyform_wrapper_text_title'>{t('buy_trading_instruments')}</span>
          <div className='buyform_wrapper_text_decor'>
            <DecorLine />
          </div>
          <div className='buyform_wrapper_text_about1'>{t('quickly_purchase')}</div>
          <div className='buyform_wrapper_text_about2'>{t('become_trading_instruments_owner')}</div>
          <div className='buyform_wrapper_text_decor'>
            <DecorLine />
          </div>
          <div className='buyform_wrapper_text_about1'>{t('easily_withdraw')}</div>
          <div className='buyform_wrapper_text_about2'>{t('withdraw_your_funds')}</div>
          <div className='buyform_wrapper_text_logo'>
            <span>{t('we_accept')}</span>
            <img src='/img/visa.png' alt='' />
            <img src='/img/master.png' alt='' />
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyForm;
