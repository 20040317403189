import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import DecorLine from '../../components/common/DecorLine';
import CfdTableDesktop from '../../components/common/CfdTableDesktop';
import { getCurrencies } from '../../store/thunks/currenciesThunk';

const TradingCFDSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => dispatch(getCurrencies()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <div className='tradingcfd_wrapper'>
      <div className='tradingcfd_wrapper_text'>
        <span className='tradingcfd_wrapper_text_title'>{t('trading_assets')}</span>
        <div className='tradingcfd_wrapper_text_line '>
          <DecorLine />
        </div>
      </div>
      <CfdTableDesktop />
    </div>
  );
};

export default TradingCFDSection;
