const ExpandArrowIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1051_1285)'>
        <path
          d='M10.8476 6.00004L8.26097 8.58671L5.67431 6.00004C5.54975 5.87521 5.38065 5.80505 5.20431 5.80505C5.02796 5.80505 4.85886 5.87521 4.73431 6.00004C4.47431 6.26004 4.47431 6.68004 4.73431 6.94004L7.79431 10C8.05431 10.26 8.47431 10.26 8.73431 10L11.7943 6.94004C12.0543 6.68004 12.0543 6.26004 11.7943 6.00004C11.5343 5.74671 11.1076 5.74004 10.8476 6.00004Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1051_1285'>
          <rect width='16' height='16' fill='white' transform='translate(0.264404)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExpandArrowIcon;
