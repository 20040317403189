import { createSlice } from '@reduxjs/toolkit';
import { getCurrencies } from '../thunks/currenciesThunk';

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: {
    currencies: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
      state.currencies = payload;
    });
  },
});

export default currenciesSlice.reducer;
