import React from 'react';

const CanadianDollarJapaneseYenIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
        fill='#F0F0F0'
      />
      <path
        d='M16 20.3478C18.4012 20.3478 20.3478 18.4013 20.3478 16C20.3478 13.5988 18.4012 11.6522 16 11.6522C13.5988 11.6522 11.6522 13.5988 11.6522 16C11.6522 18.4013 13.5988 20.3478 16 20.3478Z'
        fill='#D80027'
      />
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#F0F0F0'
      />
      <path
        d='M20 10C20 6.03543 17.6928 2.60981 14.3478 0.99231V19.0077C17.6928 17.3903 20 13.9646 20 10Z'
        fill='#D80027'
      />
      <path
        d='M0 10C0 13.9646 2.30723 17.3903 5.65219 19.0078V0.99231C2.30723 2.60981 0 6.03543 0 10Z'
        fill='#D80027'
      />
      <path
        d='M11.7391 11.3044L13.4782 10.4348L12.6087 10V9.13045L10.8696 10L11.7391 8.26088H10.8696L10 6.95654L9.13043 8.26088H8.26086L9.13043 10L7.39129 9.13045V10L6.52176 10.4348L8.26086 11.3044L7.8261 12.1739H9.56524V13.4783H10.4348V12.1739H12.1739L11.7391 11.3044Z'
        fill='#D80027'
      />
    </svg>
  );
};

export default CanadianDollarJapaneseYenIcon;
