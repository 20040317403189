import React from 'react';

const EfficiencyIcon = () => {
  return (
    <svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 2.68832C7.03085 2.68832 2.18835 7.53082 2.18835 13.5C2.18835 19.4692 7.03085 24.3117 13 24.3117C18.9692 24.3117 23.8117 19.4692 23.8117 13.5C23.8117 7.53082 18.9692 2.68832 13 2.68832ZM13 22.145C8.23335 22.145 4.35502 18.2667 4.35502 13.5C4.35502 8.73332 8.23335 4.85499 13 4.85499C17.7667 4.85499 21.645 8.73332 21.645 13.5C21.645 18.2667 17.7667 22.145 13 22.145ZM13.8125 5.91666L8.93752 15.125H12.3392V21.0833L17.0625 11.875H13.8125V5.91666Z'
        fill='url(#paint0_linear_1033_987)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1033_987'
          x1='2.24842'
          y1='2.4721'
          x2='25.4024'
          y2='4.25867'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B5BBF5' />
          <stop offset='0.477083' stop-color='#B8F5EE' />
          <stop offset='1' stop-color='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EfficiencyIcon;
