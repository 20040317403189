import React from 'react';

const ChinaFlagIcon = () => {
  return (
    <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1067_1036)'>
        <g clip-path='url(#clip1_1067_1036)'>
          <rect width='22' height='16' rx='2' fill='#F93939' />
          <path
            d='M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z'
            fill='#F93939'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.76004 7.71195L4.22004 8.53649L4.51337 6.79035L3.2688 5.55302L4.99004 5.30022L5.76004 3.71088L6.52899 5.30022L8.25023 5.55302L7.00356 6.79035L7.29899 8.53542L5.76004 7.71195ZM9.4288 3.19995H10.4764V4.26662H9.4288V3.19995ZM10.4764 5.33328H11.524V6.39995H10.4764V5.33328ZM10.4764 7.46662H11.524V8.53328H10.4764V7.46662ZM9.4288 9.59995H10.4764V10.6666H9.4288V9.59995Z'
            fill='#FFDA2C'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1067_1036'>
          <rect width='22' height='16' fill='white' />
        </clipPath>
        <clipPath id='clip1_1067_1036'>
          <rect width='22' height='16' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChinaFlagIcon;
