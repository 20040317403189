import React from 'react';

const EuroNewZealandDollarIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 16.0024 16 6.00109 16 6C21.5228 6 26 10.4772 26 16Z'
        fill='#0052B4'
      />
      <path
        d='M15.9733 16H16C16 15.991 16 15.9823 16 15.9733C15.9911 15.9822 15.9822 15.9911 15.9733 16Z'
        fill='#F0F0F0'
      />
      <path
        d='M16 11.2174C16 9.45781 16 8.30488 16 6H15.9983C10.4762 6.00094 6 10.4777 6 16H11.2174V13.0621L14.1554 16H15.9733C15.9822 15.9911 15.9911 15.9822 16 15.9732C16 15.2995 16 14.6984 16 14.1554L13.062 11.2174H16Z'
        fill='#F0F0F0'
      />
      <path
        d='M11.0592 7.30444C9.4952 8.19503 8.19497 9.49526 7.30438 11.0593V16.0001H9.91309V9.91323V9.91315H16C16 9.09038 16 8.30655 16 7.30444H11.0592Z'
        fill='#D80027'
      />
      <path
        d='M16.0001 14.7704L12.4472 11.2175C12.4472 11.2175 11.2175 11.2176 11.2175 11.2175V11.2176L16 16.0001H16.0001C16.0001 16.0001 16.0001 15.1522 16.0001 14.7704Z'
        fill='#D80027'
      />
      <path
        d='M23.3176 13.4203L23.5334 14.0846H24.2319L23.6668 14.4951L23.8827 15.1594L23.3176 14.7489L22.7525 15.1594L22.9684 14.4951L22.4032 14.0846H23.1017L23.3176 13.4203Z'
        fill='#D80027'
      />
      <path
        d='M20.8208 18.2029L21.1446 19.1994H22.1923L21.3446 19.8151L21.6685 20.8116L20.8208 20.1958L19.9732 20.8116L20.297 19.8151L19.4493 19.1994H20.497L20.8208 18.2029Z'
        fill='#D80027'
      />
      <path
        d='M20.9151 10.3768L21.1849 11.2073H22.058L21.3516 11.7203L21.6214 12.5507L20.9151 12.0375L20.2087 12.5507L20.4785 11.7203L19.7722 11.2073H20.6453L20.9151 10.3768Z'
        fill='#D80027'
      />
      <path
        d='M18.5126 13.3915L18.8364 14.388H19.8841L19.0365 15.0037L19.3603 16.0002L18.5126 15.3844L17.665 16.0002L17.9888 15.0037L17.1412 14.388H18.1888L18.5126 13.3915Z'
        fill='#D80027'
      />
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#00418F'
      />
      <path
        d='M10 3.91309L10.3238 4.90953H11.3715L10.5239 5.52535L10.8477 6.5218L10 5.90594L9.15239 6.5218L9.47618 5.52535L8.62856 4.90953H9.67626L10 3.91309Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69583 5.69582L6.62942 6.17145L7.37024 5.43066L7.2063 6.46543L8.13985 6.94109L7.10505 7.105L6.94114 8.13984L6.46548 7.20629L5.43071 7.37023L6.17153 6.62941L5.69583 5.69582Z'
        fill='#FFDA44'
      />
      <path
        d='M3.91309 10L4.90953 9.67624V8.62854L5.52531 9.4762L6.52179 9.15241L5.9059 10L6.52179 10.8476L5.52531 10.5239L4.90953 11.3715V10.3238L3.91309 10Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69583 14.3042L6.17149 13.3706L5.43071 12.6298L6.46551 12.7938L6.9411 11.8602L7.10505 12.895L8.13981 13.0589L7.20637 13.5346L7.37024 14.5694L6.62942 13.8286L5.69583 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M10 16.087L9.67622 15.0906H8.62856L9.47622 14.4748L9.15239 13.4784L10 14.0942L10.8477 13.4784L10.5239 14.4748L11.3715 15.0906H10.3238L10 16.087Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 14.3042L13.3707 13.8286L12.6298 14.5694L12.7938 13.5346L11.8603 13.0589L12.895 12.895L13.0589 11.8602L13.5346 12.7938L14.5693 12.6298L13.8285 13.3707L14.3042 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M16.087 10L15.0905 10.3238V11.3715L14.4747 10.5239L13.4784 10.8476L14.0942 10L13.4784 9.15241L14.4748 9.4762L15.0905 8.62854V9.67627L16.087 10Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 5.6959L13.8286 6.6295L14.5694 7.37032L13.5345 7.20633L13.0589 8.13989L12.895 7.10508L11.8603 6.94114L12.7938 6.46551L12.6298 5.43079L13.3707 6.17157L14.3042 5.6959Z'
        fill='#FFDA44'
      />
    </svg>
  );
};

export default EuroNewZealandDollarIcon;
