import './styles.scss';
import { useTranslation } from 'react-i18next';

const SellButton = () => {
  const { t } = useTranslation();
  return (
    <button type='button' className='sell-button'>
      {t('sell')}
    </button>
  );
};

export default SellButton;
