const ButtonRightArrowIcon = () => {
  return (
    <svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 3.89368C0.723858 3.89368 0.5 4.11753 0.5 4.39368C0.5 4.66982 0.723858 4.89368 1 4.89368V3.89368ZM15.3536 4.74723C15.5488 4.55197 15.5488 4.23539 15.3536 4.04012L12.1716 0.858143C11.9763 0.662881 11.6597 0.662881 11.4645 0.858143C11.2692 1.05341 11.2692 1.36999 11.4645 1.56525L14.2929 4.39368L11.4645 7.2221C11.2692 7.41737 11.2692 7.73395 11.4645 7.92921C11.6597 8.12447 11.9763 8.12447 12.1716 7.92921L15.3536 4.74723ZM1 4.89368H15V3.89368H1V4.89368Z'
        fill='url(#paint0_linear_104_87)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_104_87'
          x1='8'
          y1='4.39368'
          x2='8'
          y2='5.39368'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B5BBF5' />
          <stop offset='0.477083' stopColor='#B8F5EE' />
          <stop offset='1' stopColor='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ButtonRightArrowIcon;
