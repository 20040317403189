import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './locale/en.json';
import ES from './locale/es.json';
import FR from './locale/fr.json';
import RU from './locale/ru.json';
import ZH from './locale/zh.json';
import JA from './locale/ja.json';
import PT from './locale/pt.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      es: {
        translation: ES,
      },
      fr: {
        translation: FR,
      },
      ru: {
        translation: RU,
      },
      zh: {
        translation: ZH,
      },
      ja: {
        translation: JA,
      },
      pt: {
        translation: PT,
      },
    },
    lng: window.navigator.language,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
