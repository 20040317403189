import './styles.scss';

const FormInput = ({ title, value, onChange }) => {
  return (
    <div className='form_input'>
      {title}
      <input type='text' inputMode='decimal' value={value} onChange={onChange} />
    </div>
  );
};

export default FormInput;
