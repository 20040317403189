import React from 'react';

const InnovationIcon = () => {
  return (
    <svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.0483 5.39667L21.1142 8.46251L18.0483 11.5283L14.9825 8.46251L18.0483 5.39667ZM9.75 5.91667V10.25H5.41667V5.91667H9.75ZM20.5833 16.75V21.0833H16.25V16.75H20.5833ZM9.75 16.75V21.0833H5.41667V16.75H9.75ZM18.0483 2.33084L11.9167 8.45167L18.0483 14.5833L24.18 8.45167L18.0483 2.33084ZM11.9167 3.75001H3.25V12.4167H11.9167V3.75001ZM22.75 14.5833H14.0833V23.25H22.75V14.5833ZM11.9167 14.5833H3.25V23.25H11.9167V14.5833Z'
        fill='url(#paint0_linear_1033_977)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1033_977'
          x1='3.30814'
          y1='2.12166'
          x2='25.7195'
          y2='3.85183'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B5BBF5' />
          <stop offset='0.477083' stop-color='#B8F5EE' />
          <stop offset='1' stop-color='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InnovationIcon;
