const UsdIcon = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20.5C15.5229 20.5 20 16.0228 20 10.5C20 4.97715 15.5229 0.5 10 0.5C4.47716 0.5 0 4.97715 0 10.5C0 16.0228 4.47716 20.5 10 20.5Z" fill="#F0F0F0"/>
      <path d="M9.56519 10.5001H20C20 9.59749 19.8797 8.72311 19.6555 7.89136H9.56519V10.5001Z" fill="#D80027"/>
      <path d="M9.56519 5.28266H18.5322C17.9201 4.28375 17.1374 3.40083 16.2248 2.67395H9.56519V5.28266Z" fill="#D80027"/>
      <path d="M10 20.5001C12.3535 20.5001 14.5167 19.6866 16.2249 18.3262H3.77515C5.48335 19.6866 7.64652 20.5001 10 20.5001Z" fill="#D80027"/>
      <path d="M1.46773 15.7174H18.5323C19.0237 14.9155 19.4049 14.0389 19.6555 13.1087H0.344482C0.595147 14.0389 0.976281 14.9155 1.46773 15.7174V15.7174Z" fill="#D80027"/>
      <path d="M4.6322 2.06164H5.54349L4.69583 2.67746L5.01962 3.67391L4.172 3.05809L3.32438 3.67391L3.60407 2.81309C2.85774 3.43477 2.2036 4.16312 1.66454 4.97469H1.95653L1.41696 5.36668C1.33289 5.50691 1.25227 5.64937 1.175 5.79394L1.43266 6.58695L0.951956 6.23769C0.832463 6.49086 0.723166 6.74973 0.624923 7.01398L0.908791 7.88773H1.95653L1.10887 8.50355L1.43266 9.5L0.585041 8.88418L0.0773049 9.25308C0.0264844 9.6616 0 10.0777 0 10.5H10C10 4.97719 10 4.32609 10 0.5C8.02455 0.5 6.18302 1.07305 4.6322 2.06164V2.06164ZM5.01962 9.5L4.172 8.88418L3.32438 9.5L3.64817 8.50355L2.80052 7.88773H3.84825L4.172 6.89129L4.49575 7.88773H5.54349L4.69583 8.50355L5.01962 9.5ZM4.69583 5.59051L5.01962 6.58695L4.172 5.97113L3.32438 6.58695L3.64817 5.59051L2.80052 4.97469H3.84825L4.172 3.97824L4.49575 4.97469H5.54349L4.69583 5.59051ZM8.60658 9.5L7.75897 8.88418L6.91135 9.5L7.23514 8.50355L6.38748 7.88773H7.43521L7.75897 6.89129L8.08272 7.88773H9.13045L8.28279 8.50355L8.60658 9.5ZM8.28279 5.59051L8.60658 6.58695L7.75897 5.97113L6.91135 6.58695L7.23514 5.59051L6.38748 4.97469H7.43521L7.75897 3.97824L8.08272 4.97469H9.13045L8.28279 5.59051ZM8.28279 2.67746L8.60658 3.67391L7.75897 3.05809L6.91135 3.67391L7.23514 2.67746L6.38748 2.06164H7.43521L7.75897 1.0652L8.08272 2.06164H9.13045L8.28279 2.67746Z" fill="#0052B4"/>
    </svg>
  );
};

export default UsdIcon;
