const PhoneLaptopIcon = () => {
  return (
    <svg width='31' height='25' viewBox='0 0 31 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.75 3.125H22.25V5.375H24.5V2.375C24.4531 1.57812 23.7969 0.921875 23 0.875H5C4.15625 0.921875 3.5 1.57812 3.5 2.375V14.375H1.25C0.828125 14.375 0.5 14.75 0.5 15.125V15.875C0.5 17.5625 1.8125 18.875 3.45312 18.875H17V14.375H5.75V3.125ZM28.8125 6.875H20.1875C19.25 6.875 18.5 7.67188 18.5 8.5625V23.1875C18.5 24.125 19.25 24.875 20.1875 24.875H28.8125C29.7031 24.875 30.5 24.125 30.5 23.1875V8.5625C30.5 7.67188 29.7031 6.875 28.8125 6.875ZM28.25 22.625H20.75V9.125H28.25V22.625Z'
        fill='url(#paint0_linear_102_1452)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_102_1452'
          x1='0.583333'
          y1='0.635008'
          x2='32.6003'
          y2='3.72306'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B5BBF5' />
          <stop offset='0.477083' stopColor='#B8F5EE' />
          <stop offset='1' stopColor='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PhoneLaptopIcon;
