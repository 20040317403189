import { useTranslation } from 'react-i18next';
import './styles.scss';
import ButtonRightArrowIcon from '../icons/ButtonRightArrowIcon';

const PutMoneyButton = () => {
  const { t } = useTranslation();

  return (
    <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
      <button type='button' className='putmoney-button'>
        <span>{t('start_trading')}</span>
        <ButtonRightArrowIcon />
      </button>
    </a>
  );
};

export default PutMoneyButton;
