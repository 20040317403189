import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCurrencies = createAsyncThunk('currencies', async () => {
  const { data } = await axios.get(
    `https://gateway-dev.platform.i88.io/services/trading/api/symbols/forex`,
  );

  return data;
});
