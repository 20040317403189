const ShieldCheckIcon = () => {
  return (
    <svg width='25' height='27' viewBox='0 0 25 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.85 12.5604C7.6 12.3064 7.2 12.3064 7 12.5604L5.85 13.6781C5.6 13.9322 5.6 14.3386 5.85 14.5418L10.4 19.216C10.6 19.47 11 19.47 11.25 19.216L19.85 10.5282C20.1 10.2741 20.1 9.86767 19.85 9.66445L18.75 8.49591C18.5 8.24188 18.15 8.24188 17.9 8.49591L10.85 15.6088L7.85 12.5604ZM23 5.0919L13.4 1.02742C13.1 0.925806 12.8 0.875 12.5 0.875C12.15 0.875 11.85 0.925806 11.55 1.02742L1.95 5.0919C1.05 5.49835 0.5 6.36205 0.5 7.32737C0.5 17.4378 6.2 24.3982 11.55 26.6845C12.15 26.9385 12.8 26.9385 13.4 26.6845C17.7 24.8555 24.5 18.6063 24.5 7.32737C24.5 6.36205 23.9 5.49835 23 5.0919ZM12.5 24.3982C7.6 22.366 2.9 15.9644 2.9 7.32737L12.5 3.26288L22.1 7.32737C22.1 16.1676 17.15 22.4168 12.5 24.3982Z'
        fill='url(#paint0_linear_102_1433)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_102_1433'
          x1='0.566666'
          y1='0.615008'
          x2='26.288'
          y2='2.44701'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B5BBF5' />
          <stop offset='0.477083' stopColor='#B8F5EE' />
          <stop offset='1' stopColor='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShieldCheckIcon;
