import React from 'react';

const NewZealandUsdIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
        fill='#F0F0F0'
      />
      <path
        d='M15.5652 16.0001H26C26 15.0975 25.8797 14.2231 25.6555 13.3914H15.5652V16.0001Z'
        fill='#D80027'
      />
      <path
        d='M15.5652 10.7827H24.5322C23.9201 9.78375 23.1374 8.90083 22.2248 8.17395H15.5652V10.7827Z'
        fill='#D80027'
      />
      <path
        d='M16 26.0001C18.3535 26.0001 20.5167 25.1866 22.2249 23.8262H9.77518C11.4834 25.1866 13.6465 26.0001 16 26.0001Z'
        fill='#D80027'
      />
      <path
        d='M7.46772 21.2174H24.5323C25.0237 20.4154 25.4048 19.5389 25.6555 18.6086H6.34448C6.59515 19.5389 6.97628 20.4154 7.46772 21.2174Z'
        fill='#D80027'
      />
      <path
        d='M10.6322 7.56164H11.5435L10.6958 8.17746L11.0196 9.17391L10.172 8.55809L9.32437 9.17391L9.60406 8.31309C8.85773 8.93477 8.20359 9.66313 7.66453 10.4747H7.95652L7.41695 10.8667C7.33289 11.0069 7.25227 11.1494 7.175 11.2939L7.43266 12.087L6.95195 11.7377C6.83246 11.9909 6.72316 12.2497 6.62492 12.514L6.90879 13.3877H7.95652L7.10887 14.0036L7.43266 15L6.58504 14.3842L6.0773 14.7531C6.02648 15.1616 6 15.5777 6 16H16C16 10.4772 16 9.82609 16 6C14.0245 6 12.183 6.57305 10.6322 7.56164ZM11.0196 15L10.172 14.3842L9.32437 15L9.64816 14.0036L8.80051 13.3877H9.84824L10.172 12.3913L10.4957 13.3877H11.5435L10.6958 14.0036L11.0196 15ZM10.6958 11.0905L11.0196 12.087L10.172 11.4711L9.32437 12.087L9.64816 11.0905L8.80051 10.4747H9.84824L10.172 9.47824L10.4957 10.4747H11.5435L10.6958 11.0905ZM14.6066 15L13.7589 14.3842L12.9113 15L13.2351 14.0036L12.3875 13.3877H13.4352L13.7589 12.3913L14.0827 13.3877H15.1304L14.2828 14.0036L14.6066 15ZM14.2828 11.0905L14.6066 12.087L13.7589 11.4711L12.9113 12.087L13.2351 11.0905L12.3875 10.4747H13.4352L13.7589 9.47824L14.0827 10.4747H15.1304L14.2828 11.0905ZM14.2828 8.17746L14.6066 9.17391L13.7589 8.55809L12.9113 9.17391L13.2351 8.17746L12.3875 7.56164H13.4352L13.7589 6.5652L14.0827 7.56164H15.1304L14.2828 8.17746Z'
        fill='#0052B4'
      />
      <path
        d='M20 10C20 15.5228 15.5228 20 10 20C4.47719 20 0 15.5228 0 10C0 10.0024 10 0.00109375 10 0C15.5228 0 20 4.47719 20 10Z'
        fill='#0052B4'
      />
      <path
        d='M9.97324 10H9.99999C9.99999 9.99104 9.99999 9.98229 9.99999 9.97327C9.99109 9.98221 9.98218 9.99112 9.97324 10Z'
        fill='#F0F0F0'
      />
      <path
        d='M10 5.21738C10 3.45781 10 2.30488 10 0H9.99832C4.47625 0.0009375 0 4.4777 0 10H5.21738V7.06207L8.15535 10H9.97328C9.98219 9.99109 9.99113 9.98219 10 9.97324C10 9.29953 10 8.6984 10 8.15539L7.06203 5.21738H10Z'
        fill='#F0F0F0'
      />
      <path
        d='M5.05923 1.30444C3.4952 2.19503 2.19497 3.49526 1.30438 5.05929V10.0001H3.91309V3.91323V3.91315H10C10 3.09038 10 2.30655 10 1.30444H5.05923Z'
        fill='#D80027'
      />
      <path
        d='M10.0001 8.77038L6.44723 5.21753C6.44723 5.21753 5.2175 5.21761 5.2175 5.21753V5.21761L10 10.0001H10.0001C10.0001 10.0001 10.0001 9.15218 10.0001 8.77038Z'
        fill='#D80027'
      />
      <path
        d='M17.3176 7.42029L17.5334 8.08458H18.2319L17.6668 8.49513L17.8827 9.15939L17.3176 8.74888L16.7525 9.15939L16.9683 8.49513L16.4032 8.08458H17.1017L17.3176 7.42029Z'
        fill='#D80027'
      />
      <path
        d='M14.8208 12.2029L15.1446 13.1994H16.1923L15.3446 13.8151L15.6685 14.8116L14.8208 14.1958L13.9731 14.8116L14.297 13.8151L13.4493 13.1994H14.497L14.8208 12.2029Z'
        fill='#D80027'
      />
      <path
        d='M14.9151 4.37683L15.1849 5.20726H16.058L15.3516 5.72035L15.6214 6.55074L14.9151 6.0375L14.2087 6.55074L14.4785 5.72035L13.7722 5.20726H14.6453L14.9151 4.37683Z'
        fill='#D80027'
      />
      <path
        d='M12.5126 7.39148L12.8364 8.38796H13.8841L13.0365 9.00374L13.3603 10.0002L12.5126 9.38437L11.665 10.0002L11.9888 9.00374L11.1411 8.38796H12.1888L12.5126 7.39148Z'
        fill='#D80027'
      />
    </svg>
  );
};

export default NewZealandUsdIcon;
