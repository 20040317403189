import React from 'react';

const FranceFlagIcon = () => {
  return (
    <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1067_1028)'>
        <g clip-path='url(#clip1_1067_1028)'>
          <rect width='22' height='16' rx='2' fill='white' />
          <path fill-rule='evenodd' clip-rule='evenodd' d='M0 0H7V16H0V0Z' fill='#1A47B8' />
          <path fill-rule='evenodd' clip-rule='evenodd' d='M15 0H22V16H15V0Z' fill='#F93939' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1067_1028'>
          <rect width='22' height='16' fill='white' />
        </clipPath>
        <clipPath id='clip1_1067_1028'>
          <rect width='22' height='16' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FranceFlagIcon;
