import React from 'react';

const JapanFlagIcon = () => {
  return (
    <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1067_1040)'>
        <rect width='22' height='16' rx='2' fill='white' />
        <path
          d='M10.9999 11.7334C13.025 11.7334 14.6666 10.0619 14.6666 8.00006C14.6666 5.93819 13.025 4.26672 10.9999 4.26672C8.97487 4.26672 7.33325 5.93819 7.33325 8.00006C7.33325 10.0619 8.97487 11.7334 10.9999 11.7334Z'
          fill='#F93939'
        />
      </g>
      <defs>
        <clipPath id='clip0_1067_1040'>
          <rect width='22' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JapanFlagIcon;
