import React from 'react';

const EuroJapaneseYenIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
        fill='#F0F0F0'
      />
      <path
        d='M16 20.3478C18.4012 20.3478 20.3478 18.4013 20.3478 16C20.3478 13.5988 18.4012 11.6522 16 11.6522C13.5988 11.6522 11.6522 13.5988 11.6522 16C11.6522 18.4013 13.5988 20.3478 16 20.3478Z'
        fill='#D80027'
      />
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#0052B4'
      />
      <path
        d='M10.0001 3.91309L10.3238 4.90953H11.3715L10.5239 5.52535L10.8477 6.5218L10.0001 5.90594L9.1524 6.5218L9.47619 5.52535L8.62857 4.90953H9.67627L10.0001 3.91309Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69581 5.69582L6.62941 6.17145L7.37023 5.43066L7.20628 6.46543L8.13984 6.94109L7.10503 7.105L6.94112 8.13984L6.46546 7.20629L5.43069 7.37023L6.17152 6.62941L5.69581 5.69582Z'
        fill='#FFDA44'
      />
      <path
        d='M3.91309 10L4.90953 9.67624V8.62854L5.52531 9.4762L6.52179 9.15241L5.9059 10L6.52179 10.8476L5.52531 10.5239L4.90953 11.3715V10.3238L3.91309 10Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69581 14.3042L6.17148 13.3706L5.43069 12.6298L6.4655 12.7938L6.94109 11.8602L7.10503 12.895L8.1398 13.0589L7.20636 13.5346L7.37023 14.5694L6.62941 13.8286L5.69581 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M10.0001 16.087L9.67623 15.0906H8.62857L9.47623 14.4748L9.1524 13.4784L10.0001 14.0942L10.8477 13.4784L10.5239 14.4748L11.3715 15.0906H10.3238L10.0001 16.087Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 14.3042L13.3707 13.8286L12.6298 14.5694L12.7937 13.5346L11.8603 13.0589L12.895 12.895L13.0589 11.8602L13.5346 12.7938L14.5693 12.6298L13.8285 13.3707L14.3042 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M16.087 10L15.0906 10.3238V11.3715L14.4747 10.5239L13.4784 10.8476L14.0942 10L13.4784 9.15241L14.4748 9.4762L15.0906 8.62854V9.67627L16.087 10Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 5.6959L13.8285 6.6295L14.5694 7.37032L13.5345 7.20633L13.0589 8.13989L12.895 7.10508L11.8603 6.94114L12.7937 6.46551L12.6298 5.43079L13.3707 6.17157L14.3042 5.6959Z'
        fill='#FFDA44'
      />
    </svg>
  );
};

export default EuroJapaneseYenIcon;
