const InstagramIcon = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12.75' r='12' fill='url(#paint0_linear_102_1970)' />
      <path
        d='M14.8293 5.75H9.17068C6.87096 5.75 5 7.62096 5 9.92068V15.5793C5 17.879 6.87096 19.75 9.17068 19.75H14.8293C17.129 19.75 19 17.879 19 15.5793V9.92068C19 7.62096 17.129 5.75 14.8293 5.75ZM17.5916 15.5793C17.5916 17.1049 16.3549 18.3416 14.8293 18.3416H9.17068C7.64513 18.3416 6.4084 17.1049 6.4084 15.5793V9.92068C6.4084 8.3951 7.64513 7.1584 9.17068 7.1584H14.8293C16.3549 7.1584 17.5916 8.3951 17.5916 9.92068V15.5793Z'
        fill='white'
      />
      <path
        d='M11.9999 9.12903C10.0034 9.12903 8.37903 10.7534 8.37903 12.7499C8.37903 14.7464 10.0034 16.3708 11.9999 16.3708C13.9965 16.3708 15.6208 14.7465 15.6208 12.7499C15.6208 10.7533 13.9965 9.12903 11.9999 9.12903ZM11.9999 14.9624C10.778 14.9624 9.78743 13.9719 9.78743 12.7499C9.78743 11.528 10.778 10.5374 11.9999 10.5374C13.2219 10.5374 14.2124 11.528 14.2124 12.7499C14.2124 13.9718 13.2218 14.9624 11.9999 14.9624Z'
        fill='white'
      />
      <path
        d='M15.6279 10.0239C16.1071 10.0239 16.4955 9.6354 16.4955 9.15622C16.4955 8.67703 16.1071 8.28857 15.6279 8.28857C15.1487 8.28857 14.7603 8.67703 14.7603 9.15622C14.7603 9.6354 15.1487 10.0239 15.6279 10.0239Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_102_1970'
          x1='24'
          y1='24.75'
          x2='0.499999'
          y2='1.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E09B3D' />
          <stop offset='0.3' stopColor='#C74C4D' />
          <stop offset='0.6' stopColor='#C21975' />
          <stop offset='1' stopColor='#7024C4' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
