import { useTranslation } from 'react-i18next';
import './styles.scss';
import SliderButton from '../common/SliderButton';
// import SliderButtonArrowRight from '../common/SliderButtonArrowRight';
// import SliderButtonArrowLeft from '../common/SliderButtonArrowLeft';

const HeadSlider = () => {
  const { t } = useTranslation();
  return (
    <div className='head-slider'>
      <div className='head-slider_content'>
        <div className='head-slider_content_text'>
          <span className='head-slider_content_text_title'>{t('empower_forex_trading')}</span>
          <img className='head-slider_content_text_img' src='/img/head_table_bg2.png' alt='img' />
          <div className='head-slider_content_text_button'>
            <SliderButton />
            <a
              href='https://app.i88.io/'
              className='head-slider_content_text_button_demo'
              target='_blank'
              rel='noreferrer'
            >
              {t('demo_account')}
            </a>
          </div>
          {/* <div className='head-slider_content_text_arrow'> */}
          {/*  <SliderButtonArrowLeft /> */}
          {/*  <SliderButtonArrowRight /> */}
          {/* </div> */}
          <div className='head-slider_content_text_slide'>
            <div className='head-slider_content_text_slide_item' />
            <div className='head-slider_content_text_slide_item' />
            <div className='head-slider_content_text_slide_item' />
            <div className='head-slider_content_text_slide_item' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadSlider;
