import { useTranslation } from 'react-i18next';
import './styles.scss';
import DecorLine from '../common/DecorLine';
import PutMoneyButton from '../common/PutMoneyButton';

const MobileCoin = () => {
  const { t } = useTranslation();

  return (
    <div className='mobilecoin_wrapper'>
      <div className='mobilecoin_wrapper_content'>
        <div className='mobilecoin_wrapper_content_block'>
          <img className='mobilecoin_wrapper_content_block_img' src='/img/mobile_coin.png' alt='' />
          <div className='mobilecoin_wrapper_content_block_title'>{t('put_money_work')}</div>
          <div className='mobilecoin_wrapper_content_block_decor'>
            <DecorLine />
          </div>
          <div className='mobilecoin_wrapper_content_block_text'>{t('invest_wisely')}</div>
          <PutMoneyButton />
        </div>
      </div>
    </div>
  );
};

export default MobileCoin;
