const SpeedIcon = () => {
  return (
    <svg width='29' height='23' viewBox='0 0 29 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.0347 13.8884L17.0764 14.8705C16.4931 14.0357 15.5694 13.4464 14.5 13.4464C12.75 13.4464 11.3889 14.8705 11.3889 16.5893C11.3889 17.1786 11.5347 17.7188 11.7778 18.1607H17.1736C17.3194 17.8661 17.4167 17.5714 17.5139 17.2277L22.4722 16.1964C23.1042 16.0491 23.5417 15.4598 23.3958 14.8214C23.25 14.183 22.6667 13.7411 22.0347 13.8884ZM14.5 0.875C6.72222 0.875 0.5 7.20982 0.5 15.0179C0.5 17.6205 1.18056 20.0759 2.39583 22.1384C2.63889 22.6295 3.17361 22.875 3.70833 22.875H25.2431C25.7778 22.875 26.3125 22.6295 26.5556 22.1384C27.7708 20.0759 28.5 17.6205 28.5 15.0179C28.5 7.20982 22.2292 0.875 14.5 0.875ZM24.8056 20.5179H4.14583C3.27083 18.8482 2.83333 16.9821 2.83333 15.0179C2.83333 8.53571 8.03472 3.23214 14.5 3.23214C20.9167 3.23214 26.1667 8.53571 26.1667 15.0179C26.1667 16.9821 25.6806 18.8482 24.8056 20.5179Z'
        fill='url(#paint0_linear_102_1431)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_102_1431'
          x1='0.577777'
          y1='0.655007'
          x2='30.4502'
          y2='3.5886'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B5BBF5' />
          <stop offset='0.477083' stopColor='#B8F5EE' />
          <stop offset='1' stopColor='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SpeedIcon;
