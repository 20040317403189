import React from 'react';

const UsdSwissFrancIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
        fill='#D80027'
      />
      <path
        d='M21.2174 14.2609H17.7391V10.7826H14.2608V14.2609H10.7826V17.7391H14.2608V21.2174H17.7391V17.7391H21.2174V14.2609Z'
        fill='#F0F0F0'
      />
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#F0F0F0'
      />
      <path
        d='M9.56522 10.0001H20C20 9.09749 19.8797 8.22311 19.6555 7.39136H9.56522V10.0001Z'
        fill='#D80027'
      />
      <path
        d='M9.56522 4.78266H18.5322C17.9201 3.78375 17.1374 2.90083 16.2248 2.17395H9.56522V4.78266Z'
        fill='#D80027'
      />
      <path
        d='M10 20.0001C12.3535 20.0001 14.5167 19.1866 16.2249 17.8262H3.77518C5.48338 19.1866 7.64654 20.0001 10 20.0001Z'
        fill='#D80027'
      />
      <path
        d='M1.46772 15.2174H18.5323C19.0237 14.4154 19.4048 13.5389 19.6555 12.6086H0.344482C0.595146 13.5389 0.976279 14.4154 1.46772 15.2174Z'
        fill='#D80027'
      />
      <path
        d='M4.63219 1.56164H5.54348L4.69582 2.17746L5.01961 3.17391L4.17199 2.55809L3.32437 3.17391L3.60406 2.31309C2.85773 2.93477 2.20359 3.66313 1.66453 4.47469H1.95652L1.41695 4.86668C1.33289 5.00691 1.25227 5.14937 1.175 5.29395L1.43266 6.08695L0.951953 5.7377C0.832461 5.99086 0.723164 6.24973 0.624922 6.51398L0.908789 7.38773H1.95652L1.10887 8.00356L1.43266 9L0.585039 8.38418L0.0773047 8.75309C0.0264844 9.1616 0 9.57769 0 10H10C10 4.47719 10 3.82609 10 0C8.02453 0 6.18301 0.573047 4.63219 1.56164ZM5.01961 9L4.17199 8.38418L3.32437 9L3.64816 8.00356L2.80051 7.38773H3.84824L4.17199 6.39129L4.49574 7.38773H5.54348L4.69582 8.00356L5.01961 9ZM4.69582 5.09051L5.01961 6.08695L4.17199 5.47113L3.32437 6.08695L3.64816 5.09051L2.80051 4.47469H3.84824L4.17199 3.47824L4.49574 4.47469H5.54348L4.69582 5.09051ZM8.60656 9L7.75895 8.38418L6.91133 9L7.23512 8.00356L6.38746 7.38773H7.4352L7.75895 6.39129L8.0827 7.38773H9.13043L8.28277 8.00356L8.60656 9ZM8.28277 5.09051L8.60656 6.08695L7.75895 5.47113L6.91133 6.08695L7.23512 5.09051L6.38746 4.47469H7.4352L7.75895 3.47824L8.0827 4.47469H9.13043L8.28277 5.09051ZM8.28277 2.17746L8.60656 3.17391L7.75895 2.55809L6.91133 3.17391L7.23512 2.17746L6.38746 1.56164H7.4352L7.75895 0.565195L8.0827 1.56164H9.13043L8.28277 2.17746Z'
        fill='#0052B4'
      />
    </svg>
  );
};

export default UsdSwissFrancIcon;
