import './styles.scss';
import DownloadAppMobile from '../../components/DownloadAppMobile';
import BuyForm from '../../components/BuyForm';
import MobileCoin from '../../components/MobileCoin';

const FormMoneyAppSection = () => {
  return (
    <div className='putmoney_wrapper'>
      <BuyForm />
      <MobileCoin />
      <DownloadAppMobile />
    </div>
  );
};

export default FormMoneyAppSection;
