const TwitterIcon = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 12.75C0 6.12258 5.37258 0.75 12 0.75C18.6274 0.75 24 6.12258 24 12.75C24 19.3774 18.6274 24.75 12 24.75C5.37258 24.75 0 19.3774 0 12.75Z'
        fill='#55ACEE'
      />
      <path
        d='M11.6406 10.5037L11.6658 10.9189L11.2461 10.8681C9.71843 10.6732 8.38383 10.0122 7.25067 8.90211L6.69668 8.3513L6.55399 8.75805C6.25181 9.66477 6.44487 10.6223 7.0744 11.2664C7.41015 11.6223 7.33461 11.6731 6.75544 11.4613C6.55399 11.3935 6.37772 11.3426 6.36093 11.3681C6.30218 11.4274 6.50363 12.1985 6.66311 12.5036C6.88134 12.9273 7.32621 13.3425 7.81305 13.5882L8.22434 13.7831L7.73751 13.7916C7.26746 13.7916 7.25067 13.8001 7.30103 13.9781C7.46891 14.5289 8.13201 15.1136 8.87066 15.3678L9.39108 15.5457L8.93781 15.8169C8.26631 16.2067 7.4773 16.427 6.68829 16.444C6.31057 16.4525 6 16.4864 6 16.5118C6 16.5965 7.02404 17.0711 7.61999 17.2575C9.40786 17.8083 11.5315 17.571 13.1263 16.6304C14.2595 15.961 15.3926 14.6306 15.9214 13.3425C16.2068 12.6561 16.4922 11.4019 16.4922 10.8003C16.4922 10.4105 16.5174 10.3596 16.9874 9.89357C17.2644 9.6224 17.5246 9.32581 17.575 9.24107C17.6589 9.08007 17.6505 9.08007 17.2224 9.22412C16.509 9.47835 16.4083 9.44445 16.7608 9.06312C17.021 8.79195 17.3316 8.30046 17.3316 8.1564C17.3316 8.13098 17.2057 8.17335 17.063 8.24961C16.9119 8.33435 16.5761 8.46146 16.3243 8.53773L15.8711 8.68179L15.4598 8.40214C15.2331 8.24961 14.9142 8.08013 14.7463 8.02929C14.3182 7.91065 13.6635 7.9276 13.2774 8.06318C12.2282 8.44451 11.5651 9.4275 11.6406 10.5037Z'
        fill='white'
      />
    </svg>
  );
};

export default TwitterIcon;
