const GreenUpArrowIcon = () => {
  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.11797 0.874423C6.31467 0.670006 6.64185 0.670006 6.83855 0.874423L10.8409 5.03375C11.1465 5.3514 10.9214 5.88043 10.4806 5.88043H2.47595C2.03512 5.88043 1.81 5.3514 2.11566 5.03375L6.11797 0.874423Z'
        fill='#25B171'
      />
      <rect x='4.80432' y='4.80435' width='3.34783' height='6.69565' rx='0.5' fill='#25B171' />
    </svg>
  );
};

export default GreenUpArrowIcon;
