import './styles.scss';
import { useTranslation } from 'react-i18next';

const FormButton = () => {
  const { t } = useTranslation();

  return (
    <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
      <button type='button' className='form-button'>
        {t('buy_now')}
      </button>
    </a>
  );
};

export default FormButton;
