import { useTranslation } from 'react-i18next';
import './styles.scss';

const BuyButton = () => {
  const { t } = useTranslation();

  return (
    <button type='button' className='buy-button'>
      {t('buy')}
    </button>
  );
};

export default BuyButton;
