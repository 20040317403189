import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../assets/localization/i18n';
import './styles.scss';
import ExpandArrowIcon from '../icons/ExpandArrowIcon';
// import LanguageIcon from '../icons/LanguageIcon';
import MenuButton from '../MenuButton';
// import MenuMobileIcon from '../icons/MenuMobileIcon';
import EnglishFlagIcon from '../icons/EnglishFlagIcon';
import EspaniaFlagIcon from '../icons/EspaniaFlagIcon';
import FranceFlagIcon from '../icons/FranceFlagIcon';
import RussiaFlagIcon from '../icons/RussiaFlagIcon';
import ChinaFlagIcon from '../icons/ChinaFlagIcon';
import JapanFlagIcon from '../icons/JapanFlagIcon';
import PortugalFlagIcon from '../icons/PortugalFlagIcon';
import OpenedArrowIcon from '../icons/OpenedArrowIcon';
// import PopupMenu from '../PopupMenu';

const HeaderMenu = () => {
  const { t } = useTranslation();

  const languageMenu = [
    {
      id: 'en',
      title: 'English',
      icon: <EnglishFlagIcon />,
    },
    {
      id: 'es',
      title: 'Español',
      icon: <EspaniaFlagIcon />,
    },
    {
      id: 'fr',
      title: 'Français',
      icon: <FranceFlagIcon />,
    },
    {
      id: 'ru',
      title: 'Русский',
      icon: <RussiaFlagIcon />,
    },
    {
      id: 'zh',
      title: '中国人',
      icon: <ChinaFlagIcon />,
    },
    {
      id: 'ja',
      title: '日本語',
      icon: <JapanFlagIcon />,
    },
    {
      id: 'pt',
      title: 'Português',
      icon: <PortugalFlagIcon />,
    },
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(languageMenu[0]);
  const dropdownRef = useRef(null);

  const handleChangeLanguage = async (langItem) => {
    await setActiveLanguage(langItem);
    await i18n.changeLanguage(langItem.id);
    setIsDropdownOpen(false);
  };

  // const tradingMenu = [
  //   { title: 'Hot assets', link: 'test1' },
  //   { title: 'Stock', link: 'test1' },
  //   { title: 'Cryptocurrencies', link: 'test1' },
  //   { title: 'Forex', link: 'test1' },
  //   { title: 'Metals', link: 'test1' },
  //   { title: 'Indices', link: 'test1' },
  //   { title: 'Agriculture', link: 'test1' },
  //   { title: 'Oil and gas', link: 'test1' },
  // ];

  // const platformMenu = [
  //   { title: 'News', link: 'test1' },
  //   { title: 'FAQ', link: 'test1' },
  //   { title: 'Education', link: 'test1' },
  //   { title: 'Professional trading', link: 'test1' },
  //   { title: 'Payment Methods', link: 'test1' },
  //   { title: 'Status levels', link: 'test1' },
  // ];

  // const companyMenu = [
  //   { title: 'About company', link: 'test1' },
  //   { title: 'Affiliate program', link: 'test1' },
  //   { title: 'Privacy Policy', link: 'test1' },
  // ];

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className='header'>
      <div className='header_left'>
        <a href='/'>
          <img src='/img/main-logo.png' alt='logo' />
        </a>
        {/* <div className='header_left_menu'> */}
        {/*  <div className='header_left_menu_item menu_trading'> */}
        {/*    <span>Trading</span> */}
        {/*    <ExpandArrowIcon /> */}
        {/*  </div> */}
        {/*  <div className='trading'> */}
        {/*    <PopupMenu menu={tradingMenu} /> */}
        {/*  </div> */}
        {/*  <div className='header_left_menu_item menu_platform'> */}
        {/*    <span>Platform</span> */}
        {/*    <ExpandArrowIcon /> */}
        {/*  </div> */}
        {/*  <div className='platform'> */}
        {/*    <PopupMenu menu={platformMenu} /> */}
        {/*  </div> */}
        {/*  <div className='header_left_menu_item menu_company'> */}
        {/*    <span>Company</span> */}
        {/*    <ExpandArrowIcon /> */}
        {/*  </div> */}
        {/*  <div className='company'> */}
        {/*    <PopupMenu menu={companyMenu} /> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
      <div className='header_right'>
        <a
          href='https://app.i88.io/login'
          target='_blank'
          rel='noreferrer'
          className='header_right_link'
        >
          <MenuButton title={t('login')} />
        </a>
        <a
          href='https://app.i88.io/registration'
          target='_blank'
          rel='noreferrer'
          className='header_right_link'
        >
          <MenuButton title={t('register')} />
        </a>
        <div className='header_right_dropdown' ref={dropdownRef}>
          <button
            type='button'
            className='header_right_language'
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {activeLanguage.icon}
            <span className='header_right_language_text'>
              {activeLanguage.id.charAt(0).toUpperCase() + activeLanguage.id.slice(1)}
            </span>
            {isDropdownOpen ? <OpenedArrowIcon /> : <ExpandArrowIcon />}
          </button>
          {isDropdownOpen && (
            <div className='header_right_content'>
              {languageMenu.map((item) => (
                <button
                  type='button'
                  className='header_right_button'
                  key={item.id}
                  onClick={() => handleChangeLanguage(item)}
                >
                  {item.icon}
                  <span>{item.title}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <div className='header_mobmenu'> */}
      {/*  <MenuMobileIcon /> */}
      {/* </div> */}
    </div>
  );
};

export default HeaderMenu;
