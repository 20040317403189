import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import 'reset-css';
import './index.scss';
import './assets/localization/i18n';
import reportWebVitals from './reportWebVitals';
import Footer from './components/common/Footer';
import StartTradingSection from './sections/StartTradingSection';
import MenuSliderSection from './sections/MenuSliderSection';
import TradingCFDSection from './sections/TradingCFDSection';
import FormMoneyAppSection from './sections/FormMoneyAppSection';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MenuSliderSection />
      <TradingCFDSection />
      <StartTradingSection />
      <FormMoneyAppSection />
      <Footer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
