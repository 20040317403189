import './styles.scss';

const FormSelect = ({ icon, options }) => {
  return (
    <div className='form_select'>
      {icon}
      {/* <select name='select'> */}
      <div>
        {options.slice(0, 1).map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </div>
      {/* </select> */}
    </div>
  );
};

export default FormSelect;
