import React from 'react';

const SecurityIcon = () => {
  return (
    <svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 1.58334L3.25 5.91668V12.4167C3.25 18.4292 7.41 24.0517 13 25.4167C18.59 24.0517 22.75 18.4292 22.75 12.4167V5.91668L13 1.58334ZM13 13.4892H20.5833C20.0092 17.9525 17.03 21.9283 13 23.1742V13.5H5.41667V7.32501L13 3.95584V13.4892Z'
        fill='url(#paint0_linear_1033_982)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1033_982'
          x1='3.30417'
          y1='1.34502'
          x2='24.2254'
          y2='2.6658'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B5BBF5' />
          <stop offset='0.477083' stop-color='#B8F5EE' />
          <stop offset='1' stop-color='#DDB5F5' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SecurityIcon;
