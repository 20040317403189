import React from 'react';

const OpenedArrowIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.8476 9.99996L8.26097 7.41329L5.67431 9.99996C5.54975 10.1248 5.38065 10.1949 5.20431 10.1949C5.02796 10.1949 4.85886 10.1248 4.73431 9.99996C4.47431 9.73996 4.47431 9.31996 4.73431 9.05996L7.79431 5.99996C8.05431 5.73996 8.47431 5.73996 8.73431 5.99996L11.7943 9.05996C12.0543 9.31996 12.0543 9.73996 11.7943 9.99996C11.5343 10.2533 11.1076 10.26 10.8476 9.99996Z'
        fill='white'
      />
    </svg>
  );
};

export default OpenedArrowIcon;
