import './styles.scss';

const TradingBlock = ({ title, icon, text }) => {
  return (
    <div className='trading-block'>
      <div className='trading-block_title'>
        <div className='trading-block_title_icon'>{icon}</div>
        <div className='trading-block_title_name'>{title}</div>
      </div>
      <div className='trading-block_text'>{text}</div>
    </div>
  );
};

export default TradingBlock;
