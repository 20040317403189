import React from 'react';

const EuroPoundSterlingIcon = () => {
  return (
    <svg viewBox='0 0 26 26' width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
        fill='#F0F0F0'
      />
      <path
        d='M8.06718 9.91174C7.28167 10.9337 6.68937 12.1117 6.34448 13.3913H11.5467L8.06718 9.91174Z'
        fill='#0052B4'
      />
      <path
        d='M25.6555 13.3914C25.3106 12.1118 24.7182 10.9339 23.9328 9.91187L20.4533 13.3914H25.6555Z'
        fill='#0052B4'
      />
      <path
        d='M6.34448 18.6088C6.6894 19.8884 7.28171 21.0663 8.06718 22.0883L11.5466 18.6088H6.34448Z'
        fill='#0052B4'
      />
      <path
        d='M22.0882 8.06722C21.0662 7.28171 19.8883 6.6894 18.6087 6.34448V11.5467L22.0882 8.06722Z'
        fill='#0052B4'
      />
      <path
        d='M9.9118 23.9327C10.9338 24.7182 12.1117 25.3105 13.3913 25.6554V20.4532L9.9118 23.9327Z'
        fill='#0052B4'
      />
      <path
        d='M13.3913 6.34448C12.1117 6.6894 10.9338 7.28171 9.9118 8.06718L13.3913 11.5467V6.34448Z'
        fill='#0052B4'
      />
      <path
        d='M18.6087 25.6554C19.8883 25.3105 21.0662 24.7182 22.0882 23.9327L18.6087 20.4532V25.6554Z'
        fill='#0052B4'
      />
      <path
        d='M20.4533 18.6088L23.9328 22.0883C24.7182 21.0663 25.3106 19.8884 25.6555 18.6088H20.4533Z'
        fill='#0052B4'
      />
      <path
        d='M25.9154 14.6957H17.3044H17.3044V6.08465C16.8774 6.02906 16.4421 6 16 6C15.5579 6 15.1226 6.02906 14.6957 6.08465V14.6956V14.6956H6.08465C6.02906 15.1226 6 15.5579 6 16C6 16.4421 6.02906 16.8774 6.08465 17.3043H14.6956H14.6956V25.9154C15.1226 25.9709 15.5579 26 16 26C16.4421 26 16.8774 25.971 17.3043 25.9154V17.3044V17.3044H25.9154C25.9709 16.8774 26 16.4421 26 16C26 15.5579 25.9709 15.1226 25.9154 14.6957Z'
        fill='#D80027'
      />
      <path
        d='M18.6087 18.6087L23.071 23.071C23.2763 22.8659 23.4721 22.6514 23.6589 22.429L19.8385 18.6086H18.6087V18.6087Z'
        fill='#D80027'
      />
      <path
        d='M13.3913 18.6088H13.3912L8.92889 23.0711C9.13405 23.2763 9.34854 23.4721 9.57089 23.6589L13.3913 19.8384V18.6088Z'
        fill='#D80027'
      />
      <path
        d='M13.3913 13.3913V13.3912L8.92897 8.92883C8.72373 9.13399 8.52795 9.34848 8.34116 9.57083L12.1616 13.3913H13.3913V13.3913Z'
        fill='#D80027'
      />
      <path
        d='M18.6087 13.3914L23.0711 8.92896C22.8659 8.72373 22.6514 8.52794 22.4291 8.34119L18.6087 12.1616V13.3914Z'
        fill='#D80027'
      />
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
        fill='#0052B4'
      />
      <path
        d='M10.0001 3.91309L10.3238 4.90953H11.3715L10.5239 5.52535L10.8477 6.5218L10.0001 5.90594L9.1524 6.5218L9.47619 5.52535L8.62857 4.90953H9.67627L10.0001 3.91309Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69581 5.69582L6.62941 6.17145L7.37023 5.43066L7.20628 6.46543L8.13984 6.94109L7.10503 7.105L6.94112 8.13984L6.46546 7.20629L5.43069 7.37023L6.17152 6.62941L5.69581 5.69582Z'
        fill='#FFDA44'
      />
      <path
        d='M3.91309 10L4.90953 9.67624V8.62854L5.52531 9.4762L6.52179 9.15241L5.9059 10L6.52179 10.8476L5.52531 10.5239L4.90953 11.3715V10.3238L3.91309 10Z'
        fill='#FFDA44'
      />
      <path
        d='M5.69581 14.3042L6.17148 13.3706L5.43069 12.6298L6.4655 12.7938L6.94109 11.8602L7.10503 12.895L8.1398 13.0589L7.20636 13.5346L7.37023 14.5694L6.62941 13.8286L5.69581 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M10.0001 16.087L9.67623 15.0906H8.62857L9.47623 14.4748L9.1524 13.4784L10.0001 14.0942L10.8477 13.4784L10.5239 14.4748L11.3715 15.0906H10.3238L10.0001 16.087Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 14.3042L13.3707 13.8286L12.6298 14.5694L12.7937 13.5346L11.8603 13.0589L12.895 12.895L13.0589 11.8602L13.5346 12.7938L14.5693 12.6298L13.8285 13.3707L14.3042 14.3042Z'
        fill='#FFDA44'
      />
      <path
        d='M16.087 10L15.0906 10.3238V11.3715L14.4747 10.5239L13.4784 10.8476L14.0942 10L13.4784 9.15241L14.4748 9.4762L15.0906 8.62854V9.67627L16.087 10Z'
        fill='#FFDA44'
      />
      <path
        d='M14.3042 5.6959L13.8285 6.6295L14.5694 7.37032L13.5345 7.20633L13.0589 8.13989L12.895 7.10508L11.8603 6.94114L12.7937 6.46551L12.6298 5.43079L13.3707 6.17157L14.3042 5.6959Z'
        fill='#FFDA44'
      />
    </svg>
  );
};

export default EuroPoundSterlingIcon;
