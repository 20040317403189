import { useTranslation } from 'react-i18next';
import './styles.scss';
import InstagramIcon from '../icons/InstagramIcon';
// import TelegramIcon from '../icons/TelegramIcon';
import TwitterIcon from '../icons/TwitterIcon';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='footer_wrapper'>
      <div className='footer_wrapper_logo'>
        <div>
          <img src='/img/main-logo.png' alt='logo' />
        </div>
        <div className='footer_wrapper_logo_social'>
          <a href='https://instagram.com/i88.io' target='_blank' rel='noreferrer'>
            <InstagramIcon />
          </a>
          {/* <a href='https://twitter.com/i88_ai' target='_blank' rel='noreferrer'> */}
          {/*  <TelegramIcon /> */}
          {/* </a> */}
          <a href='https://twitter.com/i88_ai' target='_blank' rel='noreferrer'>
            <TwitterIcon />
          </a>
        </div>
      </div>

      <div className='footer_wrapper_menu'>
        <div className='footer_wrapper_menu_item'>
          <div className='footer_wrapper_menu_item_title'>
            <span>{t('trade')}</span>
          </div>
          <div className='footer_wrapper_menu_item_links'>
            <a href='/test'>{t('hot_assets')}</a>
            <a href='/test'>{t('stock')}</a>
            <a href='/test'>{t('cryptocurrencies')}</a>
            <a href='/test'>{t('forex')}</a>
            <a href='/test'>{t('metals')}</a>
            <a href='/test'>{t('indices')}</a>
            <a href='/test'>{t('agriculture')}</a>
            <a href='/test'>{t('oil_and_gas')}</a>
          </div>
        </div>
        <div className='footer_wrapper_menu_item'>
          <div className='footer_wrapper_menu_item_title'>
            <span>{t('platform')}</span>
          </div>
          <div className='footer_wrapper_menu_item_links'>
            <a href='/test'>{t('news')}</a>
            <a href='/test'>{t('faq')}</a>
            <a href='/test'>{t('education')}</a>
            <a href='/test'>{t('professional_trading')}</a>
            <a href='/test'>{t('payment_methods')}</a>
            <a href='/test'>{t('status_levels')}</a>
          </div>
        </div>
        <div className='footer_wrapper_menu_item'>
          <div className='footer_wrapper_menu_item_title'>
            <span>{t('company')}</span>
          </div>
          <div className='footer_wrapper_menu_item_links'>
            <a href='/test'>{t('about_company')}</a>
            <a href='/test'>{t('affiliate_program')}</a>
            <a href='/test'>{t('privacy_policy')}</a>
          </div>
        </div>
      </div>
      <div className='footer_wrapper_text'>
        <p>{t('risk_warning')}</p>
        <p>{t('not_accept_clients')}</p>
        <p>{t('foreign_exchange_market')}</p>
        <span className='footer_wrapper_text_copyright'>
          Secured By SSL. Copyright ©2023, ECash Investment Ltd. Powered by 88 team.
        </span>
      </div>
    </div>
  );
};

export default Footer;
