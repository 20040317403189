import { useTranslation } from 'react-i18next';
import './styles.scss';
import TradingBlock from '../../components/common/TradingBlock';
import ShieldCheckIcon from '../../components/common/icons/ShieldCheckIcon';
import SpeedIcon from '../../components/common/icons/SpeedIcon';
import PhoneLaptopIcon from '../../components/common/icons/PhoneLaptopIcon';
import DecorLine from '../../components/common/DecorLine';
import InnovationIcon from '../../components/common/icons/InnovationIcon';
import SecurityIcon from '../../components/common/icons/SecurityIcon';
import EfficiencyIcon from '../../components/common/icons/EfficiencyIcon';

const StartTradingSection = () => {
  const { t } = useTranslation();

  return (
    <div className='start_wrapper'>
      <div className='start_wrapper_text'>
        <span className='start_wrapper_text_title'>{t('start_trading_now')}</span>
        <span className='start_wrapper_text_about'>{t('early_entry')}</span>
        <DecorLine />
      </div>
      <div className='start_wrapper_promo'>
        <TradingBlock
          title={t('reliability')}
          icon={<ShieldCheckIcon />}
          text={t('reliability_text')}
        />
        <TradingBlock title={t('speed')} icon={<SpeedIcon />} text={t('speed_text')} />
        <TradingBlock
          title={t('convenience')}
          icon={<PhoneLaptopIcon />}
          text={t('convenience_text')}
        />
        <TradingBlock
          title={t('innovation')}
          icon={<InnovationIcon />}
          text={t('innovation_text')}
        />
        <TradingBlock title={t('security')} icon={<SecurityIcon />} text={t('security_text')} />
        <TradingBlock
          title={t('efficiency')}
          icon={<EfficiencyIcon />}
          text={t('efficiency_text')}
        />
      </div>
    </div>
  );
};

export default StartTradingSection;
