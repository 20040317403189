import { useTranslation } from 'react-i18next';
import './styles.scss';

const SliderButton = () => {
  const { t } = useTranslation();

  return (
    <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
      <button type='button' className='slider-button'>
        {t('get_started')}
      </button>
    </a>
  );
};

export default SliderButton;
