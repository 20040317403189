const BitcoinUsdIcon = () => {
  return (
    <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_102_871)'>
        <path
          d='M26.4958 13C26.4958 20.1781 20.676 25.9979 13.4948 25.999C6.3188 25.999 0.498961 20.1781 0.500001 12.999C0.497921 5.81984 6.31776 0 13.4969 0C20.676 0 26.4958 5.82088 26.4958 13Z'
          fill='#EAB300'
        />
        <path
          d='M18.6115 9.81551C18.4441 8.07455 16.9413 7.49111 15.0422 7.32471V4.90878H13.5727V7.26023C13.1868 7.26023 12.7916 7.26751 12.3995 7.27583V4.90878H10.93L10.929 7.32263C10.6107 7.32887 10.2977 7.33511 9.99298 7.33511V7.32783L7.96601 7.32679V8.89719C7.96601 8.89719 9.05177 8.87639 9.03305 8.89615C9.62897 8.89615 9.82242 9.24143 9.87858 9.53991V12.2918C9.92018 12.2918 9.97322 12.2938 10.0335 12.3022H9.87858L9.87754 16.1574C9.85154 16.3446 9.74129 16.6431 9.32529 16.6442C9.34401 16.6608 8.25721 16.6442 8.25721 16.6442L7.96497 18.3997H9.87858C10.2343 18.3997 10.5847 18.4059 10.9279 18.408L10.929 20.851H12.3975V18.434C12.8 18.4423 13.19 18.4454 13.5716 18.4454L13.5706 20.851H15.0401V18.4132C17.5112 18.2718 19.2428 17.6488 19.457 15.3275C19.6307 13.4586 18.7519 12.6235 17.3489 12.2866C18.2028 11.8539 18.7363 11.0895 18.6115 9.81551ZM16.5544 15.0384C16.5544 16.8636 13.4292 16.6566 12.4318 16.6566V13.4191C13.4292 13.4212 16.5544 13.1352 16.5544 15.0384ZM15.87 10.4718C15.87 12.1326 13.2617 11.9382 12.4318 11.9392V9.00431C13.2628 9.00431 15.8711 8.74015 15.87 10.4718Z'
          fill='white'
        />
        <path d='M9.88074 12.2273H10.0991V12.3885H9.88074V12.2273Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_102_871'>
          <rect width='25.9958' height='26' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitcoinUsdIcon;
