import { useTranslation } from 'react-i18next';
import './styles.scss';
import AppStoreIcon from '../common/icons/AppStoreIcon';
import GooglePlayIcon from '../common/icons/GooglePlayIcon';

const DownloadAppMobile = () => {
  const { t } = useTranslation();

  return (
    <div className='app_wrapper'>
      <div className='app_wrapper_block'>
        <span className='app_wrapper_block_title'>{t('lets_start')}</span>
        <div className='app_wrapper_block_box'>
          <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
            <button type='button' className='app_wrapper_block_box_button'>
              {t('open_account')}
            </button>
          </a>
          <div className='app_wrapper_block_box_text'>{t('download_our_app')}</div>
          <div className='app_wrapper_block_box_phone'>
            <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
              <AppStoreIcon />
            </a>
            <a href='https://app.i88.io/registration' target='_blank' rel='noreferrer'>
              <GooglePlayIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppMobile;
